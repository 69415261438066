import React from 'react'

import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import { TuixTextComponent } from 'components/TuixWebcomponents'
import { TextSize, TextVariant } from 'tuix-webcomponents'

import { CustomerFile } from '../Newsletter'
import './NewsletterRes.scss'

export type NewsletterResponseModel = {
    doneIcon: CustomerFile
    doneIconDark: CustomerFile
    failedIcon: CustomerFile
    failedIconDark: CustomerFile
    doneTitle: string
    failedTitle: string
    doneText: string
    failedText: string
}

type iconTheme = {
    light: string
    dark: string
    width: number
    height: number
}

type Props = {
    content: NewsletterResponseModel
    isError: boolean
}

export default ({ content, isError = false }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const doneIcon: iconTheme = {
        light: content.doneIcon.file.url,
        dark: content.doneIconDark.file.url,
        width: content.doneIcon.file.details.image.width,
        height: content.doneIcon.file.details.image.height,
    }
    const failedIcon: iconTheme = {
        light: content.failedIcon.file.url,
        dark: content.failedIconDark.file.url,
        width: content.failedIconDark.file.details.image.width,
        height: content.failedIconDark.file.details.image.height,
    }
    const icon = isError ? failedIcon : doneIcon
    const title = isError ? content.failedTitle : content.doneTitle
    const text = isError ? content.failedText : content.doneText

    return (
        <div className="container-newsletter-response">
            <div className="container-logo">
                <img
                    src={isDarkMode ? icon.dark : icon.light}
                    width={icon.width}
                    height={icon.height}
                    alt="done"
                    className="logo"
                />
                <TuixTextComponent size={TextSize.XL} color={TextVariant.PRIMARY} dark={isDarkMode}>
                    <h4>{title}</h4>
                </TuixTextComponent>
            </div>
            <div className="container-text">
                <TuixTextComponent
                    size={TextSize.S}
                    className="text"
                    color={isDarkMode ? TextVariant.LIGHT_GREY : TextVariant.GREY}
                    dark={isDarkMode}
                >
                    <p>{text}</p>
                </TuixTextComponent>
            </div>
        </div>
    )
}
