import { useStaticQuery, graphql } from 'gatsby'

export const usePageContent = () => {
    const {
        allContentfulIconWithText: { nodes: nodesIconWithText },
        mainImage: { nodes: nodesMainImage },
        section1: { nodes: nodesSection1 },
        section2: { nodes: nodesSection2 },
        section3: { nodes: nodesSection3 },
        section4: { nodes: nodesSection4 },
        allContentfulTextWithImage: { edges: nodesTextWithImageEdges },
        allContentfulHappyCustomers: { nodes: nodesHappyCustomers },
        allContentfulSeo: { nodes: nodesMetaData },
        allContentfulNewsletterForm: { nodes: nodesNewsletter },
    } = useStaticQuery(graphql`
        query MainPageQuery {
            mainImage: allContentfulMainImage(
                filter: { contentful_id: { eq: "6xK8uIPpPhF7545oJ1tlyo" } }
            ) {
                nodes {
                    subtitle
                    subTitleSize
                    textVariantSubtitle
                    textVariantSubtitleDark
                    title
                    titleSize
                    textVariantTitle
                    node_locale
                    textPosition
                    fontColor
                    buttonText
                    backgroundImage {
                        title
                        file {
                            url
                        }
                    }
                    backgroundImageDark {
                        title
                        file {
                            url
                        }
                    }
                }
            }

            section1: allContentfulSection(
                filter: { contentful_id: { eq: "3y2x8WD8kGyw4JyjLz6MCs" } }
            ) {
                nodes {
                    node_locale
                    contentful_id
                    title
                }
            }

            section2: allContentfulSection(
                filter: { contentful_id: { eq: "5wBRqYyBLH5TlDIpeMs6NF" } }
            ) {
                nodes {
                    node_locale
                    contentful_id
                    title
                }
            }

            section3: allContentfulSection(
                filter: { contentful_id: { eq: "5bbIHII15aAzqbKo6rbntm" } }
            ) {
                nodes {
                    node_locale
                    contentful_id
                    title
                }
            }

            section4: allContentfulSection(
                filter: { contentful_id: { eq: "7ox3rkhTA1CY8vFZmNHmBX" } }
            ) {
                nodes {
                    node_locale
                    contentful_id
                    title
                }
            }

            allContentfulTextWithImage(sort: { fields: order, order: ASC }) {
                edges {
                    node {
                        title
                        textPosition
                        image {
                            file {
                                url
                                details {
                                    image {
                                        height
                                        width
                                    }
                                }
                            }
                        }
                        paragraph {
                            internal {
                                content
                            }
                        }
                        node_locale
                    }
                }
            }

            allContentfulIconWithText(sort: { fields: order, order: ASC }) {
                nodes {
                    text
                    icon {
                        file {
                            url
                            details {
                                image {
                                    height
                                    width
                                }
                            }
                        }
                    }
                    node_locale
                }
            }

            allContentfulHappyCustomers {
                nodes {
                    buttonText
                    subtitle
                    title
                    customerLogos {
                        file {
                            url
                            details {
                                image {
                                    height
                                    width
                                }
                            }
                        }
                    }
                    node_locale
                }
            }

            allContentfulNewsletterForm(
                filter: { contentful_id: { eq: "P9Dq2FnXZIhBY9sv1stF2" } }
            ) {
                nodes {
                    darkEmailIcon {
                        file {
                            url
                            details {
                                image {
                                    height
                                    width
                                }
                            }
                        }
                    }
                    mailIcon {
                        file {
                            url
                            details {
                                image {
                                    height
                                    width
                                }
                            }
                        }
                    }
                    doneIcon {
                        file {
                            url
                            details {
                                image {
                                    height
                                    width
                                }
                            }
                        }
                    }
                    doneIconDark {
                        file {
                            url
                            details {
                                image {
                                    height
                                    width
                                }
                            }
                        }
                    }
                    failedIcon {
                        file {
                            url
                            details {
                                image {
                                    height
                                    width
                                }
                            }
                        }
                    }
                    failedIconDark {
                        file {
                            url
                            details {
                                image {
                                    height
                                    width
                                }
                            }
                        }
                    }
                    title
                    doneTitle
                    failedTitle
                    descriptionText
                    doneText
                    failedText
                    placeholderText
                    checkboxText
                    buttonText
                    node_locale
                    invalidEmailMessage
                }
            }

            allContentfulSeo(filter: { contentful_id: { eq: "2ZlOwOFr9zLqIfxmdlfSBd" } }) {
                nodes {
                    title
                    url
                    description {
                        description
                    }
                    image {
                        file {
                            url
                        }
                    }
                    node_locale
                }
            }
        }
    `)

    return {
        nodesIconWithText,
        nodesMainImage,
        nodesSection1,
        nodesSection2,
        nodesSection3,
        nodesSection4,
        nodesTextWithImageEdges,
        nodesHappyCustomers,
        nodesMetaData,
        nodesNewsletter,
    }
}
