import { TuixTextComponent } from 'components/TuixWebcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import { useIntersectionObserver } from 'components/hooks/intersectionOberver/useIntersectionObserverContext'
import * as React from 'react'
import { TextSize, TextVariant } from 'tuix-webcomponents'
import { Locale } from 'utils/enums'
import './IconWithText.scss'

interface Props {
    icon: Icon
    title: string
    textSize?: TextSize
    style?: React.CSSProperties
}

type ImageData = {
    height: number
    width: number
}

interface Details {
    image: ImageData
}

interface File {
    url: string
    details: Details
}

interface Icon {
    file: File
}

export interface IconWithTextModel {
    text: string
    icon: Icon
    node_locale: Locale
}

export default ({ icon, title, textSize = TextSize.S, style }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const ref = React.useRef<HTMLDivElement>(null)
    const registerElement = useIntersectionObserver()
    const iconUrl = icon.file.url
    const width = icon.file.details.image.width
    const height = icon.file.details.image.height

    React.useEffect(() => {
        registerElement(ref.current)
    }, [registerElement])

    return (
        <div className="iconWihText_container" ref={ref} style={style}>
            <img
                src={iconUrl}
                width={width}
                height={height}
                className="iconWihText_icon"
                alt="icon with text"
            />
            <TuixTextComponent
                dark={isDarkMode}
                color={TextVariant.PRIMARY}
                size={textSize}
                className="iconWihText_text"
            >
                {title}
            </TuixTextComponent>
        </div>
    )
}
