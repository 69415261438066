import * as React from 'react'

import clsx from 'clsx'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import IconWithText from 'components/IconWithText'
import { IconWithTextModel } from 'components/IconWithText/IconWithText'
import { TextSize } from 'tuix-webcomponents'

import './Branches.scss'
import Dots from './images/branches-dots.svg'

interface Props {
    iconWithTextEntries: readonly IconWithTextModel[]
}

export default ({ iconWithTextEntries }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()

    return (
        <div className={clsx('branches__container', isDarkMode && 'dark')}>
            {iconWithTextEntries.map((iconWithTextEntry, index) => {
                const style = {
                    animationDelay: `${index * 0.25}s`,
                }
                return (
                    <div className="branches_iconContainer" key={iconWithTextEntry.text}>
                        <Dots className="branches_dots" />
                        <IconWithText
                            icon={iconWithTextEntry.icon}
                            title={iconWithTextEntry.text}
                            textSize={TextSize.S}
                            style={style}
                        />
                    </div>
                )
            })}
        </div>
    )
}
