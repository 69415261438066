import React, { useState } from 'react'
import { Locale } from 'utils/enums'
import './Newsletter.scss'
import NewsletterForm from './NewsletterForm'
import NewsletterRes from './NewsletterResponse'
import { NewsletterResponseModel } from './NewsletterResponse/NewsletterRes'

type ImageData = {
    height: number
    width: number
}

interface Details {
    image: ImageData
}

interface File {
    url: string
    details: Details
}

export type CustomerFile = {
    file: File
}

export type NewsletterProps = NewsletterResponseModel & {
    darkEmailIcon: CustomerFile
    mailIcon: CustomerFile
    title: string
    descriptionText: string
    placeholderText: string
    invalidEmailMessage: string
    checkboxText: string
    buttonText: string
}

type Props = {
    content: NewsletterProps
    locale: Locale
}

export default ({ content, locale }: Props): JSX.Element => {
    const [error, setError] = useState(false)
    const [isClicked, setClick] = useState(false)

    const handlerError = () => {
        setError(!error)
    }

    const handlerClick = () => {
        setClick(!isClicked)
    }

    return (
        <div className="newsletter-container">
            {isClicked ? (
                <NewsletterRes content={content} isError={error} />
            ) : (
                <NewsletterForm
                    content={content}
                    locale={locale}
                    onClick={handlerClick}
                    onError={handlerError}
                />
            )}
        </div>
    )
}
