import { TuixTextComponent } from 'components/TuixWebcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import { useIntersectionObserver } from 'components/hooks/intersectionOberver/useIntersectionObserverContext'
import React, { useEffect, useRef } from 'react'
import { TextSize, TextVariant } from 'tuix-webcomponents'
import { Locale } from '../../utils/enums'
import './TextWithImage.scss'

type Props = {
    title: string
    paragraph: string
    image: Image
    textPosition: 'left' | 'right'
}

type ImageData = {
    height: number
    width: number
}

interface Details {
    image: ImageData
}

interface File {
    url: string
    details: Details
}

interface Image {
    file: File
}

interface Internal {
    content: string
}

interface Paragraph {
    internal: Internal
}

export interface TextWithImageModel {
    title: string
    textPosition: 'left' | 'right'
    image: Image
    paragraph: Paragraph
    node_locale: Locale
}

export default ({ title, paragraph, image, textPosition }: Props): JSX.Element => {
    const directionClass =
        textPosition === 'left' ? 'text-with-image_direction' : 'text-with-image_direction-reverse'

    const { isDarkMode } = useDarkModeContext()
    const imageRef = useRef(null)
    const textRef = useRef(null)
    const registerElement = useIntersectionObserver()
    const imageUrl = image.file.url
    const width = image.file.details.image.width
    const height = image.file.details.image.height

    useEffect(() => {
        registerElement(imageRef.current)
        registerElement(textRef.current)
    }, [registerElement])

    return (
        <div className={`text-with-image_container ${directionClass}`}>
            <div className="division_container">
                <div className="text-with-image_text-container" ref={textRef}>
                    <TuixTextComponent
                        size={TextSize.M}
                        color={TextVariant.PRIMARY}
                        dark={isDarkMode}
                    >
                        <h3>{title}</h3>
                    </TuixTextComponent>
                    <TuixTextComponent
                        size={TextSize.Body1}
                        color={TextVariant.PRIMARY}
                        dark={isDarkMode}
                    >
                        <p>{paragraph}</p>
                    </TuixTextComponent>
                </div>
            </div>
            <div className="division_container">
                <div className="text-with-image_img" ref={imageRef}>
                    <img
                        src={imageUrl}
                        width={width}
                        height={height}
                        alt={`${title} visual representation`}
                    />
                </div>
            </div>
        </div>
    )
}
