import React, { useState } from 'react'

import './NewsletterForm.scss'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'
import Loading from 'components/Loading'
import {
    TuixButtonComponent,
    TuixCheckboxComponent,
    TuixInputComponent,
    TuixTextComponent,
} from 'components/TuixWebcomponents'
import { useForm, Controller } from 'react-hook-form'
import {
    CheckboxLabelSide,
    DescriptionType,
    InputType,
    MainVariant,
    TextSize,
    TextVariant,
} from 'tuix-webcomponents'
import { Locale } from 'utils/enums'
import tuixClientApi from 'utils/tuixClientApi'

import { NewsletterProps } from '../Newsletter'

type FormData = {
    email: string
    checkbox: boolean
}

type Props = {
    content: NewsletterProps
    locale: Locale
    onError: () => void
    onClick: () => void
}

export type CustomEventHandler<DataType = unknown> = (e: CustomEvent<DataType>) => void

const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

export default ({ content, locale, onError, onClick }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const { isTablet, isMobile } = useDeviceWidth()
    const { control, handleSubmit } = useForm<FormData>({
        mode: 'all',
        defaultValues: {
            email: '',
        },
    })
    const [isChecked, setChecked] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const onSubmit = async (data: FormData) => {
        try {
            setLoading(true)
            await tuixClientApi.createSubscription({
                createSubscriptionDTO: {
                    email: data.email,
                    language: locale,
                },
            })

            onClick()
        } catch (error) {
            onError()
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="newsletter-form-container">
            <div className="text-container">
                <div className="icon-title-containter">
                    <img
                        src={
                            isDarkMode ? content.darkEmailIcon.file.url : content.mailIcon.file.url
                        }
                        alt="mail icon"
                    />
                    <TuixTextComponent
                        size={TextSize.XL}
                        dark={isDarkMode}
                        color={TextVariant.PRIMARY}
                    >
                        {content.title.split('|').map((piece, index) => (
                            <div key={index}>
                                {piece}
                                {isMobile ? <br /> : isTablet ? ' ' : <br />}
                            </div>
                        ))}
                    </TuixTextComponent>
                </div>
                <TuixTextComponent
                    size={TextSize.S}
                    dark={isDarkMode}
                    color={isDarkMode ? TextVariant.LIGHT_GREY : TextVariant.GREY}
                >
                    {content.descriptionText.split('|').map((piece, index) => (
                        <div key={index}>
                            {piece}
                            <br />
                        </div>
                    ))}
                </TuixTextComponent>
            </div>
            <div className="form-container_right-side">
                <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-container_fields">
                        <Controller
                            control={control}
                            name="email"
                            rules={{
                                required: 'Email is required',
                                pattern: { value: EMAIL_REGEX, message: 'Invalid email address' },
                            }}
                            render={({
                                field: { onChange, onBlur, value },
                                fieldState: { error, invalid },
                            }) => (
                                <TuixInputComponent
                                    id="email-newsletter-input"
                                    title={content.placeholderText}
                                    type={InputType.TEXT}
                                    dark={isDarkMode}
                                    onBlur={onBlur}
                                    value={value}
                                    description={error?.message}
                                    descriptionType={invalid && DescriptionType.ERROR}
                                    onChange={(e) => onChange(e.detail.value)}
                                />
                            )}
                        />
                        <TuixCheckboxComponent
                            checked={isChecked}
                            dark={isDarkMode}
                            label={content.checkboxText}
                            labelSide={CheckboxLabelSide.RIGHT}
                            onChange={() => setChecked(!isChecked)}
                        >
                            <TuixTextComponent
                                size={TextSize.Field1}
                                color={isDarkMode ? TextVariant.PRIMARY : TextVariant.GREY}
                                dark={isDarkMode}
                            >
                                {content.checkboxText}
                            </TuixTextComponent>
                        </TuixCheckboxComponent>
                    </div>
                    <div className="form-button-container">
                        <TuixButtonComponent
                            variant={MainVariant.SECONDARY}
                            disabled={!isChecked || isLoading}
                            onClick={handleSubmit(onSubmit) as unknown as CustomEventHandler}
                        >
                            {content.buttonText}
                        </TuixButtonComponent>
                        {isLoading && (
                            <div className="form-button-loading">
                                <Loading />
                            </div>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}
