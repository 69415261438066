import { TuixButtonComponent, TuixTextComponent } from 'components/TuixWebcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import { useIntersectionObserver } from 'components/hooks/intersectionOberver/useIntersectionObserverContext'
import { Link } from 'gatsby'
import { HappyCustomersModel } from 'pages/index'
import React, { useEffect, useMemo, useRef } from 'react'
import { TextSize, TextVariant } from 'tuix-webcomponents'
import { Locale } from 'utils/enums'
import { getLanguageByLocale } from 'utils/language'
import './CallToAction.scss'

interface Props {
    happyCustomers: HappyCustomersModel
    locale: Locale
}

export default ({ happyCustomers, locale }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const titlePieces = happyCustomers.title.split('|')
    const elementRef = useRef(null)
    const registerElement = useIntersectionObserver()

    const getHighlightedText = (text: string, index: number): JSX.Element => {
        const words = text.split('**')
        const highlightedText = words.map((word, i) => {
            if (i % 2 === 0) {
                return word
            } else {
                return (
                    <span key={`${index}-${i}`} className="highlighted">
                        {word}
                    </span>
                )
            }
        })
        return <>{highlightedText}</>
    }

    const renderPieces = (): JSX.Element => {
        const highlightedTexts = useMemo(() => titlePieces.map(getHighlightedText), [titlePieces])

        return (
            <TuixTextComponent size={TextSize.XL} color={TextVariant.PRIMARY} dark={isDarkMode}>
                <slot ref={elementRef} className="pieceswrapper">
                    {highlightedTexts.map((highlightedText, index) => (
                        <div key={index} className={`typewriter-${index}`}>
                            {highlightedText}
                        </div>
                    ))}
                </slot>
            </TuixTextComponent>
        )
    }

    useEffect(() => {
        registerElement(elementRef.current)
    }, [elementRef])

    return (
        <div className="section3_callToAction">
            {renderPieces()}
            <Link to={`/${getLanguageByLocale(locale)}/contact-us`}>
                <TuixButtonComponent dark={isDarkMode}>
                    {happyCustomers.buttonText}
                </TuixButtonComponent>
            </Link>
        </div>
    )
}
